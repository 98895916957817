import React, { createContext, useContext, useState, ReactNode } from "react"

interface LoadContextType {
	handleLoad(): void
	loading: boolean
	setLoading(state: boolean): void
}

const LoadContext = createContext<LoadContextType | undefined>(undefined)

export const LoadProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [loading, setLoading] = useState(false)
	const [time, setTime] = useState<any>(0)

	const handleLoad = () => {
		setLoading(true)
		clearTimeout(time)
		const myTime = setTimeout(() => {
			setLoading(false)
		}, 2000)

		setTime(myTime)
	}

	return (
		<LoadContext.Provider value={{ handleLoad, loading, setLoading }}>
			{loading && (
				<div className="fixed z-10 flex items-center justify-center w-full h-full">
					<video autoPlay src="/load.webm" className="z-50 p-2" />
					<div className="absolute z-20 items-center justify-center w-full h-full bg-gray-700 opacity-75" />
				</div>
			)}
			{children}
		</LoadContext.Provider>
	)
}

export const useLoad = () => {
	const context = useContext(LoadContext)
	if (context === undefined) {
		throw new Error("useLoad must be used within a LoadProvider")
	}
	return context
}
