import { Link, useLocation } from "react-router-dom"

const SpreadsheetTabs = ({}) => {
	const location = useLocation()

	const tabs = [
		{
			url: "/spreadsheet-manager",
			name: "Preparação de Planilhas",
		},
		{
			url: "/import-spreadsheet",
			name: "Importação de Planilhas",
		},
		{
			url: "/spreadsheet-history",
			name: "Histórico de Planilhas",
		},
	]

	return (
		<div className="flex">
			<div className="flex m-2">
				{tabs.map((item) => {
					return (
						<>
							{location.pathname === item.url ? (
								<div className="flex flex-col hover:bg-neutral-200 transition duration-500">
									<Link className="px-4 py-2 " to={item.url}>
										{item.name}
									</Link>
									<div className="w-full self-end bg-blue-500 h-1"></div>
								</div>
							) : (
								<div className="flex flex-col hover:bg-neutral-200 transition duration-500">
									<Link className="px-4 py-2 " to={item.url}>
										{item.name}
									</Link>
								</div>
							)}
						</>
					)
				})}
			</div>
		</div>
	)
}
export default SpreadsheetTabs
