import api from "@/services/api";
import { UserWithRole } from "@/types";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Função auxiliar para verificar se um cookie específico está presente
function checkCookie(name: string): boolean {
  return document.cookie
    .split(";")
    .some((cookie) => cookie.trim().startsWith(`${name}=`));
}

export type AuthContextType = {
  isAuthenticated: boolean;
  logout(): void;
  revalidate(): void;
  user: UserWithRole | undefined;
};
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState<UserWithRole>();
  const navigate = useNavigate();

  useEffect(() => {
    // Verifique se o cookie de autenticação está presente
    const isAuthenticated = checkCookie("access_token");
    if (isAuthenticated) {
      const data = localStorage.getItem("user");
      if (data) {
        const u: UserWithRole = JSON.parse(data);
        setUser(u);
      } else {
        getUser();
      }
    }
    setAuthenticated(isAuthenticated);

    // Se não estiver autenticado e o usuário não estiver na página de login, redirecione para a página de login
    if (!isAuthenticated && !window.location.pathname.startsWith("/login")) {
      navigate("/login");
    }
  }, [navigate]);

  async function revalidate() {
    getUser();
  }
  async function getUser() {
    const response = await api.get("/users/me");
    localStorage.setItem("user", JSON.stringify(response.data));
    setUser(response.data);
  }
  const logout = () => {
    document.cookie =
      "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    setAuthenticated(false);
    localStorage.clear();
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: authenticated,
        logout,
        user,
        revalidate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

// Hook personalizado para usar o contexto de autenticação
export function useAuth(): AuthContextType {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth deve ser usado dentro de um AuthProvider");
  }
  return context;
}
