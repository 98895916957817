import * as React from "react"
import * as ReactDOM from "react-dom/client"
import "./index.css"
import RouteESB from "./Route"
import { Toaster } from "@/components/ui/toaster"
import { LoadProvider } from "./context/LoadProvider"

ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<Toaster />
		<LoadProvider>
			<RouteESB />
		</LoadProvider>
	</React.StrictMode>
)
