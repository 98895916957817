import { useAuth } from "@/context/AuthProvider"
import { useCompany } from "@/context/CompanyProvider"
import api from "@/services/api"
import Loader from "@/components/app/loader"
import { useEffect, useState } from "react"
import moment from "moment"
import { Clock, Hash, Download, RefreshCcw, X } from "lucide-react"
import { Button } from "@/components/ui/button"
import { saveAs } from "file-saver"
import { useToast } from "@/components/ui/use-toast"
import SpreadsheetTabs from "@/components/app/spreadsheet-tabs"

export default function SpreadsheetHistory() {
	const { user } = useAuth()
	const { selectedCompany } = useCompany()

	const { toast } = useToast()

	const [loading, setLoading] = useState(false)
	const [spreadsheets, setSpreadsheets] = useState<
		[
			{
				id?: number
				user_id?: number
				empresa_id?: number
				line_number?: number
				progress?: number
				status?: string
				created_at?: Date
				updated_at?: Date
				finished_at?: Date
			}
		]
	>([{}])

	useEffect(() => {
		getSpreadsheets()
	}, [])

	const getSpreadsheets = async () => {
		setLoading(true)

		const spreadsheetsPayload = await api
			.get(`spreadsheet-manager/company/${selectedCompany?.id}/user/${user?.id}`)
			.then((response) => {
				setLoading(false)

				return response.data
			})
			.catch((err: any) => {
				setLoading(false)

				console.error(err)
			})

		setSpreadsheets(spreadsheetsPayload)
	}

	const buildCSV = (fileData: []) => {
		let csvContent = ""
		// Loop pelos dados processados
		fileData.forEach((row: any) => {
			row.map((itemRow: string) => {
				csvContent += `"${itemRow}",`
			})
			csvContent += "\r\n"
		})

		console.log(csvContent)
		// Criar um Blob com o conteúdo do CSV
		const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8" })
		// Salvar o arquivo CSV
		saveAs(csvBlob, "planilha_download.csv")
	}

	const downloadSpreadsheet = async (id?: number) => {
		const { file_data: fileData } = await api
			.get(`spreadsheet-manager/${id}`)
			.then((res) => {
				toast({
					variant: "default",
					title: "Planilha Baixada!",
					description: "Download da planilha concluído!",
					className: "bg-green-500 text-white",
				})
				return res.data
			})
			.catch((err) => console.error(err))

		buildCSV(JSON.parse(fileData))
	}

	const downloadErrorsSpreadsheet = async (id?: number) => {
		const fileData = await api
			.get(`spreadsheet-manager/errors/${id}`)
			.then((res) => {
				toast({
					variant: "default",
					title: "Nenhum Erro Encontrado!",
					description: "Essa planilha enviou todos leads sem problemas!",
					className: "bg-green-500 text-white",
				})
				return res.data
			})
			.catch((err) => {
				console.error(err)
				toast({
					variant: "default",
					title: "Erros baixados!",
					description: "Download dos erros concluído!",
					className: "bg-green-500 text-white",
				})
			})

		buildCSV(fileData)
	}

	const continueSpreadhseet = async (id?: number) => {
		await api
			.get(`spreadsheet-manager/continue/${id}`)
			.then(() => {
				toast({
					variant: "default",
					title: "Continuando Processamento!",
					description: "Planilha em processamento novamente!",
					className: "bg-green-500 text-white",
				})
			})
			.catch((err) => console.error(err))

		setTimeout(() => {
			window.location.reload()
		}, 500)
	}

	const cancelSpreadhseet = async (id?: number) => {
		await api
			.get(`spreadsheet-manager/cancel/${id}`)
			.then(() => {
				toast({
					variant: "default",
					title: "Processamento Cancelado!",
					description: "Essa planilha não será processada!",
					className: "bg-green-500 text-white",
				})
			})
			.catch((err) => console.error(err))

		setTimeout(() => {
			window.location.reload()
		}, 500)
	}

	return (
		<div className="w-full">
			<SpreadsheetTabs />
			<div className="flex flex-1 p-5 bg-white rounded dark">
				<Loader loading={loading} />
				<div className="container p-5 mx-auto">
					<div className="flex items-center justify-center gap-4">
						<h1 className="mb-4 mt-4 text-4xl font-semibold flex items-center flex-col">
							Histórico
						</h1>
						<Button
							className="px-4 py-2 font-bold text-white bg-green-500 rounded hover:bg-green-600"
							onClick={() => {
								getSpreadsheets()
							}}
						>
							Atualizar Planilhas
							<RefreshCcw className="ml-2" size={20} />
						</Button>
					</div>
					{spreadsheets.map((spreadsheet) => {
						return (
							<div
								key={spreadsheet.id}
								className="mb-6 mt-5 rounded bg-neutral-100 flex flex-col items-center p-4"
							>
								<div className="flex w-full justify-between mb-3">
									<div className="flex gap-2 text-center">
										<p className="flex gap-1 items-center">
											<Hash className="ml-2" size={20} />
											{spreadsheet.id}
										</p>
										<p className="flex gap-1 items-center">
											<Clock className="ml-2" size={20} />
											{moment(spreadsheet.created_at)
												.format("DD-MM-YYYY | HH:mm:ss")
												.toString()}
										</p>
									</div>
									<div className="flex gap-2">
										{spreadsheet.status === "finished" && (
											<p className="bg-green-300 rounded py-2 px-3">
												Finalizado
											</p>
										)}
										{spreadsheet.status === "in progress" && (
											<>
												{moment(spreadsheet.updated_at).add(
													5,
													"m"
												) < moment(Date.now()) ? (
													<>
														<Button
															className="px-4 py-2 font-bold text-white bg-yellow-500 rounded hover:bg-yellow-600"
															onClick={() => {
																continueSpreadhseet(
																	spreadsheet.id
																)
															}}
														>
															Continuar Envio
															<RefreshCcw
																className="ml-2"
																size={20}
															/>
														</Button>
														<p className="bg-red-300 rounded py-2 px-3">
															Parado
														</p>
													</>
												) : (
													<p className="bg-blue-300 rounded py-2 px-3">
														Em Processamento
													</p>
												)}
											</>
										)}
										{spreadsheet.status === "pending" && (
											<p className="bg-gray-300 rounded py-2 px-3">
												Pendente
											</p>
										)}
										{spreadsheet.status === "canceled" && (
											<p className="bg-red-300 rounded py-2 px-3">
												Cancelado
											</p>
										)}
										{spreadsheet.status === "in progress" && (
											<Button
												className="px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-600"
												onClick={() => {
													cancelSpreadhseet(spreadsheet.id)
												}}
											>
												Cancelar
												<X className="ml-2" size={20} />
											</Button>
										)}
										<Button
											className="px-4 py-2 font-bold text-white bg-red-500 rounded hover:bg-red-600"
											onClick={() => {
												downloadErrorsSpreadsheet(spreadsheet.id)
											}}
										>
											Checar Erros
											<Download className="ml-2" size={20} />
										</Button>
										<Button
											className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-600"
											onClick={() => {
												downloadSpreadsheet(spreadsheet.id)
											}}
										>
											Planilha
											<Download className="ml-2" size={20} />
										</Button>
									</div>
								</div>
								<div className="flex w-full gap-2 items-center">
									<p>{spreadsheet.progress}%</p>
									{spreadsheet.status === "canceled" ? (
										<div className="w-full bg-gray-300 rounded-full">
											<div
												className="h-2 bg-red-400 rounded-full"
												style={{
													width: `${spreadsheet.progress}%`,
												}}
											></div>
										</div>
									) : (
										<div className="w-full bg-gray-300 rounded-full">
											<div
												className="h-2 bg-green-400 rounded-full transition duration-500"
												style={{
													width: `${spreadsheet.progress}%`,
												}}
											></div>
										</div>
									)}
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}
