import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";

const api: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_SERVER_API as string, // Certifique-se de converter para string
});

api.interceptors.request.use(
  // @ts-ignore
  (config: AxiosRequestConfig) => {
    const token = Cookies.get("access_token");
    const company = localStorage.getItem("company"); // Não é necessário parsear aqui, pois você não precisa dos valores individuais

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };

      if (company) {
        config.headers["Empresa_id"] = JSON.parse(company).id; // Se houver uma empresa, definir o cabeçalho
      }
    }

    return config;
  },
  (error) => {
    console.log("Erro de requisição:", error); // Alterado para um log mais genérico
    window.location.href = "/login"; // Corrigido `location.href` para `window.location.href`
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response; // Retorna a resposta se não houver erro
  },
  // @ts-ignore
  (error: AxiosError) => {
    if (error.response) {
      if (error.response.data.message === "jwt invalid") {
        localStorage.clear();
        Cookies.remove("access_token");
        window.location.href = "/login";
      }
    } else {
      console.error("aaa", error);

      console.log("Erro de requisição:", error.message); // Log do erro geral
    }
    // Retorna uma promessa rejeitada com o erro original
    return Promise.reject(error);
  }
);

export default api;
