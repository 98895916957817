export type User = { name: string; email: string; id?: number };

export type Role = { name?: string; id?: number; slug?: EnumRoleSlugs };

export type Company = {
  name?: string;
  id?: number;
  token?: string;
  redirect_api?: string;
  redirect_params?: string;
  provider?: string;
  roles?: Role[];
};

export enum EnumRoleSlugs {
  ADMIN = "admin",
  PADRAO = "padrao",
  TESTER = "tester",
}
export type ParametroEmpresa = {
  id: number;
  name: string;
  value: string;
  description: string;
  read_only: boolean;
  empresa_id: number;
};
export enum FormFieldType {
  TEXT,
  LIST,
}
export type FormField = {
  type: FormFieldType;
  id?: number;
  field: string;
  key: string;
  empresa_id: number;
  read_only: boolean;
};
export interface UserWithRole extends User {
  UserRole: { role: Role }[];
}
// user with password
export interface UserWithPassword extends User {
  password: string;
}

export interface PrivateDefault {
  roles: EnumRoleSlugs[];
}

export enum Provider {
  PLAYNEE,
  BITRIX24,
}
export enum DataType {
  STRING = "STRING",
  NUMBER = "NUMBER",
  DATE = "DATE",
  TRIM = "TRIM",
}
