import React from "react";

// import { Container } from './styles';

const Loader: React.FC<{ loading: boolean }> = ({ loading = false }) => {
  if (loading === false) return <></>;
  return (
    <div className="absolute left-0 z-50 flex items-center justify-center w-full h-full duration-300 delay-300 ">
      <div className="absolute left-0 z-0 flex items-center justify-center w-full h-full duration-300 delay-300 opacity-25 bg-slate-800" />

      <div
        className=" z-10 border-blue-500 text-blue-500  inline-block h-10 w-10 animate-spin  rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      >
        <span className="!absolute text-blue-500 !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </span>
      </div>
    </div>
  );
};

export default Loader;
