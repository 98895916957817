import React, { Suspense } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { EnumRoleSlugs } from "./types"
import Loader from "./components/app/loader"
import SpreadsheetHistory from "./pages/spreadsheetHistory"

const App = React.lazy(() => import("./App"))
const Login = React.lazy(() => import("./pages/Login"))
const Home = React.lazy(() => import("./pages/Home"))
const ImportacaoBitrix24 = React.lazy(() => import("./pages/integrations"))
const UsersScreen = React.lazy(() => import("./pages/Users"))
const CreateUser = React.lazy(() => import("./pages/Users/new"))
const ErrorPage = React.lazy(() => import("./error-page"))
const PrivateRoute = React.lazy(() => import("./components/app/private-route"))
const Forbidden = React.lazy(() => import("./components/app/forbidden"))
const EditUser = React.lazy(() => import("./pages/Users/edit"))
const CompaniesScreen = React.lazy(() => import("./pages/Companies"))
const CreateCompany = React.lazy(() => import("./pages/Companies/new"))
const EditCompany = React.lazy(() => import("./pages/Companies/edit"))
const ImportSpreadsheet = React.lazy(() => import("./pages/ImportSpreadsheet"))
const SpreadsheetManager = React.lazy(() => import("./pages/SpreadsheetManager"))

const RouteESB: React.FC = () => {
	return (
		<BrowserRouter basename="/">
			<Suspense fallback={<Loader loading />}>
				<Routes>
					<Route element={<App />}>
						<Route path="integrations" element={<ImportacaoBitrix24 />} />
						<Route
							path="import-spreadsheet"
							element={<ImportSpreadsheet />}
						/>
						<Route
							path="spreadsheet-history"
							element={<SpreadsheetHistory />}
						/>
						<Route
							path="spreadsheet-manager"
							element={<SpreadsheetManager />}
						/>
						<Route path="" element={<Home />} />
						<Route
							path="users"
							index
							element={
								<PrivateRoute roles={[EnumRoleSlugs.ADMIN]}>
									<UsersScreen />
								</PrivateRoute>
							}
						/>
						<Route
							path="companies"
							element={
								<PrivateRoute roles={[EnumRoleSlugs.ADMIN]}>
									<CompaniesScreen />
								</PrivateRoute>
							}
						/>
						<Route
							path="companies/:id"
							element={
								<PrivateRoute roles={[EnumRoleSlugs.ADMIN]}>
									<EditCompany />
								</PrivateRoute>
							}
						/>
						<Route
							path="companies/new"
							element={
								<PrivateRoute roles={[EnumRoleSlugs.ADMIN]}>
									<CreateCompany />
								</PrivateRoute>
							}
						/>

						<Route
							path="teste"
							index
							element={
								<PrivateRoute roles={[EnumRoleSlugs.TESTER]}>
									<CompaniesScreen />
								</PrivateRoute>
							}
						/>
						<Route
							path="users/new"
							element={
								<PrivateRoute roles={[EnumRoleSlugs.ADMIN]}>
									<CreateUser />
								</PrivateRoute>
							}
						/>
						<Route
							path="users/:userId"
							element={
								<PrivateRoute roles={[EnumRoleSlugs.ADMIN]}>
									<EditUser />
								</PrivateRoute>
							}
						/>
					</Route>
					<Route path="login" element={<Login />} />
					<Route path="forbidden" element={<Forbidden />} />
					<Route path="*" element={<ErrorPage />} />
				</Routes>
			</Suspense>
		</BrowserRouter>
	)
}

export default RouteESB
