import api from "@/services/api";
import { EnumRoleSlugs, Role } from "@/types";
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth } from "./AuthProvider";
import { useLoad } from "./LoadProvider";

// Definindo o tipo para uma empresa
interface Company {
  id: number;
  name: string;
}

// Definindo o tipo para o contexto de empresas
interface CompanyContextType {
  selectedCompany: Company | null;
  selectCompany: (company: Company) => void;
  roles: Role[];
  checkUserRole: (roleName: EnumRoleSlugs) => boolean;
}

// Criando o contexto para empresas
const CompanyContext = createContext<CompanyContextType | undefined>(undefined);

// Provedor do contexto de empresas
export const CompanyProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [roles, setRoles] = useState<Role[]>([]);
  const { user } = useAuth();
  const { handleLoad } = useLoad();
  useEffect(() => {
    const company = localStorage.getItem("company");
    if (company) {
      setSelectedCompany(JSON.parse(company));
    }
  }, []);
  useEffect(() => {
    getUserRoles();
  }, [user, selectedCompany]);

  const checkUserRole = (roleName: EnumRoleSlugs) => {
    if (!user) return false;

    return roles.some((role) => role.slug === roleName);
  };
  async function getUserRoles() {
    if (!user) return false;

    // @ts-ignore
    const { data } = await api.get(
      `/user-roles-empresas/users/${user?.id}/companies/${selectedCompany?.id}/roles`
    );

    setRoles(data.map((e: any) => e.role));
  }

  const selectCompany = (company: Company) => {
    localStorage.setItem("company", JSON.stringify(company));
    setSelectedCompany(company);
    handleLoad();
  };

  return (
    <CompanyContext.Provider
      value={{ selectedCompany, selectCompany, roles, checkUserRole }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

// Hook customizado para acessar o contexto de empresas
export const useCompany = () => {
  const context = useContext(CompanyContext);
  if (context === undefined) {
    throw new Error("useCompany must be used within a CompanyProvider");
  }
  return context;
};
